*,
::before,
::after {
    box-sizing: border-box;
    /* 1 */
    border-width: 0;
    /* 2 */
    border-style: solid;
    /* 2 */
    border-color: #e5e7eb;
    /* 2 */
}

::before,
::after {
    --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
    line-height: 1.5;
    /* 1 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
    -moz-tab-size: 4;
    /* 3 */
    tab-size: 4;
    /* 3 */
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
    margin: 0;
    /* 1 */
    line-height: inherit;
    /* 2 */
}


button,
input,
textarea {
    font-family: inherit;
    /* 1 */
    font-size: 100%;
    /* 1 */
    line-height: inherit;
    /* 1 */
    color: inherit;
    /* 1 */
    margin: 0;
    /* 2 */
    padding: 0;
    /* 3 */
}

button {
    text-transform: none;
}

button,
[type='button'],
[type='submit'] {
    -webkit-appearance: button;
    /* 1 */
    background-color: transparent;
    /* 2 */
    background-image: none;
    /* 2 */
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

p {
    margin: 0;
}


ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}


input::-moz-placeholder, textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    opacity: 1;
    color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
}

button {
    cursor: pointer;
}

img,
svg {
    display: block;
    /* 1 */
    vertical-align: middle;
    /* 2 */
}

.hidden {
    display: none;
}

[hidden] {
    display: none;
}

.container {
    width: 100%;
}

.h-full {
    height: 100vh;
}

.flex {
    display: flex;
}

/*@media (min-width: 480px) {*/
/*    .container {*/
/*        max-width: 480px;*/
/*    }*/
/*}*/

/*@media (min-width: 850px) {*/
/*    .container {*/
/*        max-width: 850px;*/
/*    }*/
/*}*/

/*@media (min-width: 1024px) {*/
/*    .container {*/
/*        max-width: 1024px;*/
/*    }*/
/*    .md\:w-1\/2Contact {*/
/*        width: 50%;*/
/*    }*/
/*}*/

/*@media (min-width: 1280px) {*/
/*    .container {*/
/*        max-width: 1280px;*/
/*    }*/
/*}*/

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.left-0 {
    left: 0;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
}

.hidden {
    display: none;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor {
    cursor: default;
}

.flex-col {
    flex-direction: column;
}

.items-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.gap-5 {
    gap: 1.5rem;
}

.overflow-hidden {
    overflow: hidden;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-sm {
    border-radius: 0.125rem;
}

.rounded-b-3xl {
    border-radius: 1.5rem;
}

/*////////////////////////////////////////////////*/
/*Background*/
/*////////////////////////////////////////////////*/

.bg-white {
    background: #faf9f9;
    color: black;
}

.bg-white1 {
    background: #ffffff;
    color: #ffffff;
}

.bg-black {
    background: #1c1b1b;
    color: white;

}

.bg-black1 {
    background: #000000;
    color: white;

}

/*////////////////////////////////////////////////*/
/*Background arc en ciel*/
/*////////////////////////////////////////////////*/


.dark .bg-bar10 {
    background: #a157c8;
    background: -webkit-linear-gradient(0deg, #a157c8 0%, #385ede 100%);
}

@-webkit-keyframes Animation {
    0% {
        background-position: 10% 0
    }
    50% {
        background-position: 91% 100%
    }
    100% {
        background-position: 10% 0
    }
}

@-moz-keyframes Animation {
    0% {
        background-position: 10% 0
    }
    50% {
        background-position: 91% 100%
    }
    100% {
        background-position: 10% 0
    }
}

@keyframes Animation {
    0% {
        background-position: 10% 0
    }
    50% {
        background-position: 91% 100%
    }
    100% {
        background-position: 10% 0
    }
}


.from-indigo-200 {
    --tw-gradient-from: #b4d7da;
    --tw-gradient-stops: var(--tw-gradient-from), var(rgb(199 210 254 / 0));
}

.dark .from-indigo-200 {
    background-color: #000000;
}

/*////////////////////////////////////////////////*/
/*Weight et Heigth*/
/*////////////////////////////////////////////////*/


.w-full {
    width: 100%;
}

.w-1\/2 {
    width: 70%;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

/*////////////////////////////////////////////////*/
/*padding*/
/*////////////////////////////////////////////////*/
.p-15 {
    padding-top: 5rem;
}

.p-10 {
    padding: 2rem;
}

.p-8 {
    padding: 2rem;
}

.p-2 {
    padding: 0.5rem;
}

.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

/*.py-titre {*/
/*    padding-top: 2.5rem;*/
/*    padding-bottom: 2.5rem;*/
/*}*/

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.py-2contact {
    padding: 0.5rem 1rem;
}

.py-20 {
    padding-top: 6rem;
}

.py-30 {
    padding-top: 9rem;
}

.py-40 {
    padding-top: 12rem;
}

/*@media screen and (max-width: 850px) {*/
/*    .py-40 {*/
/*        padding-top: 6rem;*/
/*        !*padding-bottom: 10rem;*!*/
/*    }*/
/*    .w-1\/2 {*/
/*        width: 96%;*/
/*    }*/
/*    .w-full-contact {*/
/*        width: 65%;*/
/*    }*/
/*}*/


/*////////////////////////////////////////*/
/*Text font / Titre */
/*////////////////////////////////////////*/
@font-face {
    font-family: 'Cormorant-Garamond';
    src: url('../../Font/CormorantGaramond-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Glacial-Indifference';
    src: url('../../Font/GlacialIndifference-Bold.otf') format('truetype');
}

.text-center {
    text-align: center;
}

.font-nunito {
    font-family: Nunito, sans-serif;
}

.overlay-container {
    /*margin-top: 1.5rem;*/
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

/*.titre-elias{*/
/*    margin-left: 1.3%;*/
/*}*/

.text-top,
.text-bottom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.animate-title-top {
    animation-name: textAnimation;
    animation-duration: 1.4s;
    animation-timing-function: ease;
}

.animate-title-bottom {
    animation-name: textAnimation;
    animation-duration: 1.2s;
    animation-timing-function: ease;
}

@keyframes textAnimation {
    0% {
        transform: translate(-30%, -30%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}


.animate-title-top-mobile {
    animation-name: textAnimationMobile;
    animation-duration: 1.2s;
    animation-timing-function: ease;
}

.animate-title-bottom-mobile {
    animation-name: textAnimationMobile;
    animation-duration: 1s;
    animation-timing-function: ease;
}

@keyframes textAnimationMobile {
    0% {
        transform: translate(-10%, -45%);
    }
    100% {
        transform: translate(-50%, -50%);
    }
}

.text-top {
    font-family: 'Cormorant-Garamond', serif;
    /*font-size: 110px;*/
    letter-spacing: 13px;
}

.text-bottom {
    z-index: 1;
    font-family: 'Glacial-Indifference', serif;
    /*font-size: 17px;*/
    line-height: 15px;
    vertical-align: top;
    letter-spacing: 7px;
}

/*.textLogo1 {*/
/*    font-family: 'Cormorant-Garamond', serif;*/
/*    font-size: 65px;*/
/*    letter-spacing: 5px;*/

/*}*/

/*.textLogo2 {*/
/*    font-family: 'Glacial-Indifference', serif;*/
/*    font-size: 65px;*/
/*    letter-spacing: 5px;*/

/*}*/

/*.divMoon{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    position: static;*/
/*    right: 3%;*/
/*}*/

.moonlight {
    margin-top:4px;
    padding: 12px;
    margin-right: 24px;
    border-radius: 15%;
    /*right: 20px;*/
}

.moonlight:hover{
    background-color: #ded8d8;
}

.dark .moonlight:hover{
    background-color: #5b5858;
}

/* CSS pour les sous-titres */
.text-soustitre {
    font-family: 'Glacial-Indifference', serif;
    font-weight: 600;
    letter-spacing: 2.48px;
    display: flex;
}

/* Media Queries pour la réactivité */
@media (max-width: 768px) {
    .text-soustitre {
        font-size: 16px; /* Ajuster la taille du texte pour les écrans plus petits */
        letter-spacing: 1.8px; /* Ajuster l'espacement des lettres pour les écrans plus petits */
        flex-wrap: wrap; /* Permettre aux éléments de passer à la ligne pour une meilleure réactivité */
    }

    .text-soustitre li {
        margin: 4px; /* Ajouter un peu d'espace entre les éléments pour les écrans plus petits */
    }
}


.font-medium {
    font-weight: 500;
}

.dark .text-black {
    color: #ffffff;
}

.text-white {
    color: white;
}

.text-black {
    user-select: none;
    color: #0f1f38;
}

.dark .copyright {
    color: #ffffff;
}

.copyright {
    color: black;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    font-family: Hind, Helvetica, Arial, sans-serif;
}

.selected {
    user-select: none;
    cursor: pointer;
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px, 0 4px 6px -4px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, #0000), var(--tw-shadow);
}

.ring-1 {
    --tw-ring-offset-shadow: 0 0 0 var;
    --tw-ring-shadow: 0 0 0 calc(1px) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, #0000);
}

.ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}

.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

/*imodale image*/
.modal-button {
    cursor: pointer;
    z-index: 2;
    color: #fff;
    background-color: rgba(94, 90, 90, 0.9);
    border-radius: 10%;
    padding: 0.5rem 0.8rem;
    margin: 0.5rem;
}

.modal-button-next:hover, .modal-button-prev:hover {
    background-color: #61dafb;
}

.modal-button-prev {
    float: left;
    transform: translateY(-50%) rotate(180deg);
}

.modal-button-next {
    float: right;
    transform: translateY(-50%);
}


/*contact*/

.dark .input-contact {
    border: 3px solid rgb(44, 66, 80);
    border-radius: 5px;
}

.input-contact {
    border: 2px solid #171717;
    border-radius: 10px;
}

.btn-contact :hover {
    scale: 110%;
}


/*modale image*/
.modal {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.86);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    border-radius: 2%;
    max-width: 85%;
    max-height: 85%;
    object-fit: contain;
}

/*Taille d'ecran*/

/* Styles pour les écrans de moins de 850px de large */
@media screen and (max-width: 950px) {
    /* Masquer la liste de navigation en haut */
    .hidden {
        display: none;
    }

    /* Afficher le bouton hamburger */
    #hamburger {
        display: block;
    }

    .navbar {
        width: auto;
    }

    .titre-elias {
        margin-left: 5%;
    }

    /*imodale image*/
    .modal-button {
        cursor: pointer;
        z-index: 2;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: 10%;
        padding: 0.3rem 0.5rem;
        margin: 0.3rem;
    }

    .modal-content {
        border-radius: 2%;
        max-width: 79%;
        max-height: 79%;
        object-fit: contain;
    }

    .moonlight {
        margin-right: 0;
    }
}

@media screen and(max-width: 950px ) {
    /*.text-soustitre{*/
    /*    !*font-weight: 300;*!*/
    /*    !*font-size: 15px;*!*/
    /*    !*font-weight: 600;*!*/
    /*    !*letter-spacing: 2px;*!*/
    /*}*/

}

@media screen and (min-width: 950px) {
    .md\:hidden {
        display: none;
    }
}
