.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /*margin: 8%;*/
    margin: 0  10%;
}

.gallery-image {
    width: 100%;
    height: 100%; /* Hauteur fixe pour toutes les images */
    object-fit: cover;
    border-radius: 1%;
}

.gallery-image-container {
    width: calc(33.33% - 80px);
    height:  calc(33.33% - 0px);
    margin: 40px;
    position: relative;
    object-fit: contain;
    display: inline-block;
}

.gallery-image-spe {
    display: flex;
    object-fit: contain;
    width: 50%;
    max-width: calc(100vh);
    /*scale: 80%;*/
    /*display: block;*/
    margin: 15px;
}

@media screen and (max-width: 1300px) {
    .gallery-image-container {
        width: calc(40% - 0px);
    }
    .gallery-image-spe {
        width: calc(85% - 0px);
    }
    .gallery {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {
    .gallery-image-container {
        width: calc(80% - 0px);
    }

    .gallery {
        margin: 0;
    }
}

/*.gallery-image {*/
/*max-width: 100%;*/
/*height: auto;*/
/*width: auto;*/
/*height: auto;*/
/*max-height: calc(100vh - 200px);*/
/*margin: 0 auto;*/
/*}*/

