.titre {
    justify-content: space-between;
    position: relative;
    display: flex;
    align-items: center;
}

.logo {
    padding-right: 20px;
}

.text-5xl {
    font-size: 35px;
}

/*/////////////////////////////////////////////*/
/*carrousel*/
/*/////////////////////////////////////////////*/

.carousel .slide img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-height: calc(100vh - 200px);
    margin: auto;
}

.arrowPrev, .arrowNext {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 2;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.44);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    touch-action: none;
    top: 50%;
    margin-top: -35px;
}

.dark .arrowNext,.dark .arrowPrev{
    background-color: rgba(49, 47, 47, 0.66);
}

.arrowPrev {
    left: 10px;
    transform: translateY(-50%) rotate(180deg);
}

.arrowNext {
    right: 10px;
    transform: translateY(-50%);
}

.arrowPrev:hover, .arrowNext:hover{
    background-color: #61dafb;
}

@media (max-width: 850px) {
    .arrowNext .arrowPrev {
        display: none;
    }
}

/*navbar*/

:root {
    /*--hauteur-menu: 45px;*/
}

#navbar {
    /*overflow: hidden;*/
    /*padding: 90px 10px;*/
    transition: 0.4s;
    display: flow;
    position: relative;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    top: 0;
    z-index: 1;
}
@font-face {
    font-family: 'Migha-Medium';
    src: url('../../Font/Migha-Medium.otf') format('truetype');
}

#navbar a {
    font-family: Migha-Medium, serif;
    color: black;
    text-align: center;
    /*padding: 12px;*/
    /*margin-left: 1.2rem;*/
    /*margin-right: 1.2rem;*/
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
}

.dark #navbar a {
    color: #ffffff;
}

#navbar a:hover {
    background-color: #ded8d8;
    color: black;
}

.dark #navbar a:hover {
    background-color: #5b5858;
}

#navbar a.active {
    background-color: dodgerblue;
    color: white;
}

.container {
    padding: 30px 10px;
}

.title {
    font-size: 1.9rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    visibility: visible;
}

.categories {
    padding-top: 2.5rem;
}

.container.scroll {
    padding: 0;
}

.title.scroll {
    font-size: 0;
    padding-top: 0;
    padding-bottom: 0;
    visibility: hidden;
}

.categories.scroll {
    padding-top: 0;
}

@media screen and (max-width: 850px) {
    .mode {
        position: fixed;
        padding-bottom: 1rem;
        padding-top: 0;
        margin-right: auto;
    }
}


/*#navbar-right {*/
/*    float: right;*/
/*}*/

/*@media screen and (max-width: 580px) {*/
/*    #navbar {*/
/*        !*padding: 20px 10px !important;*!*/
/*    }*/
/*    #navbar a {*/
/*        float: none;*/
/*        display: block;*/
/*        text-align: left;*/
/*    }*/
/*    #navbar-right {*/
/*        float: none;*/
/*    }*/
/*}*/
nav ul {
    display: block;
    justify-content: space-between;
    align-items: center;
    max-width: 960px; /* ajustez la valeur à la largeur souhaitée */
    margin: 0 auto; /* centrer la barre de navigation horizontalement */
}

nav li {
    flex: 1;
}

nav li a {
    position: sticky;
    text-align: center;
    display: block;
    padding: 10px;
}

nav li a.centered {
    max-width: 200px; /* ajustez la valeur à la largeur souhaitée */
    margin: 0 auto; /* centrer l'élément horizontalement */
}

nav > ul > li > a {
    display: block;
    top: 50%;
    transform: translate(-50%, -50%);
}

li a {
    text-decoration: none;
}

/*humberger*/

.humbuger {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    width: 28px;
    height: 20px;
    position: relative;
}

.hamburger {
    /*padding-bottom: 20px;*/
    /*padding-top: 2.5rem;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.dark .colorhumberger {
    background-color: #ffffff;
}

.colorhumberger {
    background-color: #000000;
}

.humbuger span {
    width: 100%;
    height: 4px;
    transition: all 0.4s ease-in-out;
}

.humbuger.open span:first-child {
    transform: rotate(90deg) translate(11px, -10px);
}

.humbuger.open span:nth-child(2) {
    transform: rotate(90deg) translate(3px, 0px);
}

.humbuger.open span:last-child {
    transform: rotate(90deg) translate(-5px, 10px);

}

.sous-menu > li:hover {
    background-color: rgba(33, 105, 236, 0.3);
    font-size: 16px!important;
}

.bg-sous-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 95%;
    width: 250px;
    height: auto;
    padding-top: 10px;
    --tw-bg-opacity: 1;
    background-color: #e7e1e1;
}

.dark .bg-sous-menu {
    --tw-bg-opacity: 1;
    color: #61dafb;
    background-color: #13132c;
}


/*bouton scroll up*/
.scroll-to-top {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    font-size: 32px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
}

/* Styles pour les écrans de taille inférieure ou égale à 458px */
@media only screen and (max-width: 458px) {
    .scroll-to-top {
        font-size: 26px;
        bottom: 1rem;
        right: 0.5rem;
    }
}

/* Styles pour les écrans de taille inférieure ou égale à 458px */
@media only screen and (min-width: 1900px) {
    .scroll-to-top {
        bottom: 3rem;
        right: 3rem;
        font-size: 30px;
    }
}

/* Animation pour faire glisser l'élément depuis la droite de l'écran */
@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

/* Ajouter cette classe à l'élément .scroll-to-top pour appliquer l'animation */
.scroll-to-top.slide-in {
    animation: slideInRight 0.5s ease-in-out;
}

.scroll-to-top.slide-out {
    animation: slideOutRight 0.5s ease-in-out;
}

/* Animation pour faire glisser l'élément depuis la droite de l'écran */
@keyframes slideInRight {
    from {
        transform: translateX(250%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(250%);
    }
}

.dark .scroll-to-top {
    color: #ffffff;
}

.white .scroll-to-top {
    color: #000000;
}

.scroll-to-top:hover {
    color: #524d4d;
}
