.containerCard {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*position: relative;*/
    /*height: 100vh;*/
}

.textCard {
    user-select: initial;
    font-size: 24px;
    color: #000000;
    text-align: center;
    animation-name: AnimTextCard;
    animation-duration: 1.5s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.dark .textCard{
    color: #ffffff;
}

.containerCard .nameCard {
    font-family: 'Cormorant-Garamond', serif;
    font-size: 21px;
    letter-spacing: 3px;
    text-align: center;
    position: relative;
    top: 10%;
}

.bar {
    width: 50%; /* Ajustez la largeur ici */
    /*height: 2px;*/
    border: 1px solid #000000;
    border-radius: 5%;
    position: absolute;
    top: 24%;
    left: 25%
}

.dark .bar{
    border: 1px solid #ffffff;
}

.cameraCard{
    position: relative;
    display: flex;
    justify-content: center;
    /*margin-bottom: 5rem;*/
    bottom: 0;
    /*padding-left: 1rem;*/
    top: 25%;

}

@keyframes circle {
    from {
        opacity: 0.9;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(4.3);
        visibility: hidden;
    }
}

.circleCard {
    width: 100px;
    height: 100px;
    border: 80px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*position: absolute;*/
    margin-top: 25%;
    /*margin-left: 18%;*/
}

.oneCard {
    position: absolute;
    top: 10%;
    left: 20%;
    opacity: 0;
    animation: circle;
    animation-duration: 1.5s;
    animation-delay: 1.3s;
    animation-fill-mode: forwards;

    /* Custom timing function: start fast, end slow */
    /*animation-timing-function: cubic-bezier(0.4, 0, 13, 13);*/
}


/* Show the element after the animation completes */
.oneCard.animated {
    opacity: 1;
}

.containerCard .titleCard {
    position: relative;
    font-family: 'Arial', serif;
    font-size: 19px;
    bottom: 8rem;
    top: 19%;
    text-align: center;
}

.containerCard .emailCard {
    position: relative;
    justify-content: center;
    font-family: 'Arial', serif;
    font-size: 16px;
    top: 35%;
}

.containerCard .telCard {
    position: relative;
    justify-content: center;
    font-family: 'Arial', serif;
    font-size: 16px;
    top: 42%;
}

.allcard {
    max-width: 20em;
    max-height: 28em;
    width: 80%;
    height: 80%;
    border-radius: 8px;
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*TODO le probleme viens de position faut la mettre en absolute ou fixed*/
    position: absolute;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*text-align: center;*/
    /*margin: auto;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
}

.firstCard {
    border-radius: 8px;
    border: 2px solid #3a3939;
    /*transform: translate(-55%, -55%);*/
    background-color: transparent;
    /*anim*/
    animation-name: AnimFirstCard;
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.dark .firstCard{
    border: 1px solid #ffffff;
}

.card {
    border-radius: 8px;
    border: 3px solid #c4daef;
    background-color: rgba(196, 203, 224, 0.68);
    /*transform: translate(-50%, -50%);*/
    /*anim*/
    animation-name: AnimCard;
    animation-duration: 1.2s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

.dark .card{
    border: 3px solid #2d2d2d;
    background-color: rgb(34, 27, 27);
    box-shadow: 0 10px 37px rgba(103, 98, 98, 0.89);
}

.lastCard {
    border-radius: 8px;
    background-color: rgba(48, 99, 157, 0.89);
    box-shadow: 0 5px 25px rgba(42, 123, 203, 0.83);
    margin-top: 1rem;
    margin-right: 1rem;
    /*transform: translate(-45%, -50%);*/
    /*anim*/
    animation-name: AnimLastCard;
    animation-duration: 1.45s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
}

    /*.card-pink {*/
    /*    border-left: 3px solid #ff0000;*/
    /*    height: 100%;*/
    /*    border-radius: 0;*/
    /*    transform: translate(50%,-43%);*/
    /*}*/

.nonSelectalbe{
    user-select: none;
}

@keyframes AnimFirstCard {
    0% {
        opacity: 0.3;
        transform: translateX(80%) translateY(20%);
    }
    100% {
        opacity: 1;
        transform: translateX(-3%) translateY(0);
    }
}
@keyframes AnimCard {
    0% {
        opacity: 0.5;
        transform: translateX(85%) translateY(23%);
    }
    100% {
        opacity: 0.95;
        transform: translateX(1%) translateY(2%);
    }
}
@keyframes AnimLastCard {
    0% {
        opacity: 0.3;
        transform: translateX(90%) translateY(25%);
    }
    100% {
        opacity: 1;
        transform: translateX(7%) translateY(2%);
    }
}
@keyframes AnimTextCard {
    0% {
        opacity: 0.3;
        transform: translateX(-80%) translateY(80%);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
}

/*@media (min-width: 769px) {*/
/*    .circleCard {*/
/*        top: -20%;*/
/*    }*/
/*}*/






